:root {
  --color-background-button-link-active-t13dka: #e6e6e6 !important;
  --color-background-button-link-hover-69sbpz: #FFF7F7 !important;
  --color-background-button-normal-active-9lyks0: #e6e6e6 !important;
  --color-background-button-normal-hover-c3qbgm: #FFF7F7 !important;
  --color-background-button-primary-active-vg0knj: #303030 !important;
  --color-background-button-primary-default-pwn1g1: #e40501 !important;
  --color-background-button-primary-disabled-eon7km: #ebebeb !important;
  --color-background-button-primary-hover-qu1zio: #303030 !important;
  --color-background-calendar-today-762czq: #f4f4f4 !important;
  --color-background-cell-shaded-26ubfj: #f8f8f8 !important;
  --color-background-code-editor-gutter-active-line-default-p2p8kl: #6e6e6e !important;
  --color-background-code-editor-gutter-default-l5k65m: #f4f4f4 !important;
  --color-background-code-editor-pane-item-hover-lrigi6: #ebebeb !important;
  --color-background-control-checked-9admlu: #e40501 !important;
  --color-background-control-disabled-zplipy: #d6d6d6 !important;
  --color-background-dropdown-item-filter-match-6ac2mw: #FFF7F7 !important;
  --color-background-dropdown-item-hover-mqsxkv: #f4f4f4 !important;
  --color-background-dropdown-item-selected-8l4mpk: #FFF7F7 !important;
  --color-background-home-header-mpq08f: #101010 !important;
  --color-background-input-disabled-wu9j6u: #ebebeb !important;
  --color-background-item-selected-ebt4bi: #FFF7F7 !important;
  --color-background-layout-mobile-panel-9vweqk: #101010 !important;
  --color-background-layout-panel-hover-0bg3gq: #ebebeb !important;
  --color-background-layout-toggle-active-d15dki: #424242 !important;
  --color-background-layout-toggle-default-f9hnkv: #19342f !important;
  --color-background-layout-toggle-hover-gi0e0b: #424242 !important;
  --color-background-layout-toggle-selected-active-9w7pk3: #e40501 !important;
  --color-background-layout-toggle-selected-default-e1giv3: #e40501 !important;
  --color-background-layout-toggle-selected-hover-mes33s: #05997b !important;
  --color-background-modal-overlay-17dapf: rgba(48, 48, 48, 0.7) !important;
  --color-background-progress-bar-content-default-r4tyyh: #e40501 !important;
  --color-background-progress-bar-layout-default-9xbpym: #ebebeb !important;
  --color-background-segment-active-bl0u6r: #e40501 !important;
  --color-background-tiles-disabled-byt5lx: #ebebeb !important;
  --color-background-toggle-checked-disabled-07lf06: #b4f4e7 !important;
  --color-background-toggle-default-w4ftus: #4f4f4f !important;
  --color-border-button-normal-active-zbmaft: #303030 !important;
  --color-border-button-normal-default-djv8f5: #e40501 !important;
  --color-border-button-normal-disabled-262a0x: #a8a8a8 !important;
  --color-border-button-normal-hover-e1d0kh: #000000 !important;
  --color-border-button-primary-disabled-6gwabt: #ebebeb !important;
  --color-border-code-editor-ace-active-line-light-theme-3l07nr: #d6d6d6 !important;
  --color-border-code-editor-ace-active-line-dark-theme-74siyt: #6e6e6e !important;
  --color-border-code-editor-default-l9vmjf: #d6d6d6 !important;
  --color-border-code-editor-pane-item-hover-xephrt: #8a8a8a !important;
  --color-border-control-checked-4xvndc: #e40501 !important;
  --color-border-control-default-m3lmsh: #8a8a8a !important;
  --color-border-control-disabled-vx8bco: #d6d6d6 !important;
  --color-border-divider-active-nnnfx9: #8a8a8a !important;
  --color-border-divider-default-7s2wjw: #ebebeb !important;
  --color-border-divider-panel-bottom-zrjpck: #ebebeb !important;
  --color-border-divider-panel-side-yjkfcr: #ebebeb !important;
  --color-border-dropdown-container-tyn0js: #a8a8a8 !important;
  --color-border-dropdown-group-0utpsr: #ebebeb !important;
  --color-border-dropdown-item-default-faosd8: #ebebeb !important;
  --color-border-dropdown-item-hover-tyzq9m: #8a8a8a !important;
  --color-border-dropdown-item-dimmed-hover-6dhy15: #8a8a8a !important;
  --color-border-input-default-e47bkv: #8a8a8a !important;
  --color-border-input-disabled-x2lopj: #ebebeb !important;
  --color-border-item-focused-ap3b6s: #e40501 !important;
  --color-border-item-placeholder-jgzhnb: #e40501 !important;
  --color-border-item-selected-ppkssz: #e40501 !important;
  --color-border-layout-wf5alz: #d6d6d6 !important;
  --color-border-popover-5pygk7: #a8a8a8 !important;
  --color-border-segment-active-kdy5tm: #4f4f4f !important;
  --color-border-segment-default-091088: #4f4f4f !important;
  --color-border-segment-disabled-6v5zlt: #4f4f4f !important;
  --color-border-segment-hover-9notuw: #4f4f4f !important;
  --color-border-divider-interactive-default-rcasvw: #ebebeb !important;
  --color-border-tabs-divider-69fx5i: #ebebeb !important;
  --color-border-tabs-shadow-4lmh2z: hsla(0, 0%, 4%, 0.12) !important;
  --color-border-tabs-underline-9bcfzu: #e40501 !important;
  --color-border-tiles-disabled-dp4h7y: #ebebeb !important;
  --color-border-tutorial-4ioksw: #d6d6d6 !important;
  --color-shadow-default-3se70f: hsla(0, 0%, 4%, 0.12) !important;
  --color-text-status-info-utupcz: #e60301 !important;
  --color-text-accent-s1eqko: #e40501 !important;
  --color-text-body-default-ajf1h5: #101010 !important;
  --color-text-body-secondary-fdstdf: #4f4f4f !important;
  --color-text-breadcrumb-current-mum16s: #6e6e6e !important;
  --color-text-breadcrumb-icon-mlugp6: #8a8a8a !important;
  --color-text-button-inline-icon-default-w65lqn: #e40501 !important;
  --color-text-button-inline-icon-disabled-gfhvz7: #a8a8a8 !important;
  --color-text-button-inline-icon-hover-wd5icp: #303030 !important;
  --color-text-button-normal-active-6obrzh: #303030 !important;
  --color-text-button-normal-default-u8647m: #e40501 !important;
  --color-text-button-normal-hover-kxmvcc: #303030 !important;
  --color-text-link-button-normal-default-2yrp9u: #e40501 !important;
  --color-text-link-button-normal-hover-gz8y5b: #303030 !important;
  --color-text-link-button-normal-active-8lhs8f: #303030 !important;
  --color-text-calendar-day-hover-graajh: #101010 !important;
  --color-text-calendar-month-elf7e9: #6e6e6e !important;
  --color-text-code-editor-gutter-default-q46j79: #101010 !important;
  --color-text-column-header-u472sv: #4f4f4f !important;
  --color-text-column-sorting-icon-q5sr09: #4f4f4f !important;
  --color-text-control-disabled-3z3eul: #a8a8a8 !important;
  --color-text-counter-jwdegc: #6e6e6e !important;
  --color-text-disabled-a2nkh4: #a8a8a8 !important;
  --color-text-dropdown-footer-rj6jvf: #6e6e6e !important;
  --color-text-dropdown-group-label-eaqlcl: #4f4f4f !important;
  --color-text-dropdown-header-6e05nm: #101010 !important;
  --color-text-dropdown-item-default-7tg9p2: #101010 !important;
  --color-text-dropdown-item-dimmed-jyqdrs: #a8a8a8 !important;
  --color-text-dropdown-item-disabled-varol7: #a8a8a8 !important;
  --color-text-dropdown-item-filter-match-x302if: #e40501 !important;
  --color-text-dropdown-item-highlighted-oaabyk: #101010 !important;
  --color-text-dropdown-item-secondary-0me7js: #6e6e6e !important;
  --color-text-dropdown-item-secondary-hover-7ii1ru: #6e6e6e !important;
  --color-text-empty-fjv325: #6e6e6e !important;
  --color-text-expandable-section-default-k03p1p: #101010 !important;
  --color-text-expandable-section-hover-73g3du: #e40501 !important;
  --color-text-expandable-section-navigation-icon-default-lb9rm0: #4f4f4f !important;
  --color-text-form-default-yd29ox: #101010 !important;
  --color-text-form-label-k3j0b4: #101010 !important;
  --color-text-form-secondary-ih9x7l: #6e6e6e !important;
  --color-text-group-label-w9to2i: #4f4f4f !important;
  --color-text-heading-default-jopz0f: #101010 !important;
  --color-text-heading-secondary-corkaj: #4f4f4f !important;
  --color-text-home-header-secondary-qfyemi: #d6d6d6 !important;
  --color-text-icon-subtle-bz5b7h: #6e6e6e !important;
  --color-text-input-disabled-oawyzo: #a8a8a8 !important;
  --color-text-input-placeholder-j53j3c: #6e6e6e !important;
  --color-text-input-placeholder-disabled-6928j8: #a8a8a8 !important;
  --color-text-interactive-active-vol84d: #001411 !important;
  --color-text-interactive-default-eg5fsa: #4f4f4f !important;
  --color-text-interactive-disabled-3pbb07: #a8a8a8 !important;
  --color-text-interactive-hover-v3lasm: #001411 !important;
  --color-text-interactive-inverted-default-phki5u: #d6d6d6 !important;
  --color-text-label-gxskdy: #101010 !important;
  --color-text-link-default-5f186r: #e40501 !important;
  --color-text-link-hover-kololx: #303030 !important;
  --color-text-link-primary-underline-ga7rq8: #e40501 !important;
  --color-text-pagination-page-number-active-disabled-sjs1t5: #a8a8a8 !important;
  --color-text-pagination-page-number-default-z6gnaa: #4f4f4f !important;
  --color-text-segment-default-bf7rka: #4f4f4f !important;
  --color-text-segment-hover-5pccvg: #e40501 !important;
  --color-text-small-le1y42: #6e6e6e !important;
  --color-text-top-navigation-title-oypxe3: #101010 !important;
  --color-board-placeholder-active-vaxzdf: #ebebeb !important;
  --color-board-placeholder-hover-v1s7kq: #e6e6e6 !important;
  --shadow-container-fx563k: 0px 0px 1px 1px #ebebeb, 0px 1px 8px 2px hsla(0, 0%, 4%, 0.12) !important;
  --shadow-container-stacked-jfo2nn: -1px 1px 1px 0px #ebebeb, 1px 1px 1px 0px #ebebeb, 0px 9px 8px -7px hsla(0, 0%, 4%, 0.12), 8px 0px 8px -7px hsla(0, 0%, 4%, 0.12), -8px 0px 8px -7px hsla(0, 0%, 4%, 0.12) !important;
  --shadow-container-active-l4kuxc: 0px 1px 1px 1px #ebebeb, 0px 6px 36px #1010101a !important;
  --shadow-dropdown-99h0gz: 0px 4px 20px 1px hsla(0, 0%, 4%, 0.1);
  --shadow-dropup-11esug: 0px 4px 20px 1px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-flash-collapsed-d4s4kz: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-flash-sticky-xplpiu: 0px 6px 36px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-modal-3qhlqt: 0px 4px 20px 1px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-panel-gp3bf2: 0px 1px 1px 1px #ebebeb, 0px 6px 36px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-panel-toggle-3fk0ch: 0px 6px 12px 1px hsla(0, 0%, 4%, 0.12) !important;
  --shadow-popover-kzy6rd: 0px 4px 20px 1px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-split-bottom-otfetg: 0px -36px 36px -36px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-split-side-qv353u: -1px 0px 1px 0px #ebebeb, -36px 6px 36px -36px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-sticky-aijlo6: 0px 4px 20px 1px hsla(0, 0%, 4%, 0.1) !important;
  --shadow-sticky-embedded-qit8ba: 0px 2px 0px 0px #ebebeb, 0px 16px 16px -12px hsla(0, 0%, 4%, 0.1) !important;
}

.awsui-dark-mode:not(#\9) {
  --color-background-button-link-active-t13dka: #424242 !important;
  --color-background-button-link-hover-69sbpz: #19342f !important;
  --color-background-button-normal-active-9lyks0: #424242 !important;
  --color-background-button-normal-default-syw2p1: hsl(168, 5%, 20%) !important;
  --color-background-button-normal-disabled-z5vd4i: hsl(168, 5%, 20%)!important;
  --color-background-button-normal-hover-c3qbgm: #19342f !important;
  --color-background-button-primary-active-vg0knj: #df6160 !important;
  --color-background-button-primary-default-pwn1g1: #df6160 !important;
  --color-background-button-primary-disabled-eon7km: #424242 !important;
  --color-background-button-primary-hover-qu1zio: #ffffff !important;
  --color-background-calendar-today-762czq: #424242 !important;
  --color-background-cell-shaded-26ubfj: hsl(0, 0%, 19%);
  --color-background-code-editor-gutter-active-line-default-p2p8kl: #8a8a8a !important;
  --color-background-code-editor-gutter-default-l5k65m: #19342f !important;
  --color-background-code-editor-loading-4cqamf: #19342f !important;
  --color-background-code-editor-pane-item-hover-lrigi6: #424242 !important;
  --color-background-container-content-i8i4a0: hsl(1, 99%, 45%) !important;
  --color-background-container-header-4flbq5: hsl(0, 0%, 11%) !important;
  --color-background-control-checked-9admlu: #df6160 !important;
  --color-background-control-default-4pa05r: hsl(0, 0%, 11%) !important;
  --color-background-control-disabled-zplipy: #424242 !important;
  --color-background-dropdown-item-default-fzdqsg: #19342f !important;
  --color-background-dropdown-item-filter-match-6ac2mw: #424242 !important;
  --color-background-dropdown-item-hover-mqsxkv: #424242 !important;
  --color-background-dropdown-item-selected-8l4mpk: #19342f !important;
  --color-background-input-default-u57378: hsl(0, 0%, 11%) !important;
  --color-background-input-disabled-wu9j6u: #19342f !important;
  --color-background-item-selected-ebt4bi: #19342f !important;
  --color-background-layout-main-sfhm4y: hsl(0, 0%, 11%) !important;
  --color-background-layout-panel-content-808qum: hsl(0, 0%, 11%) !important;
  --color-background-layout-panel-hover-0bg3gq: #424242 !important;
  --color-background-layout-toggle-selected-active-9w7pk3: #df6160 !important;
  --color-background-layout-toggle-selected-default-e1giv3: #df6160 !important;
  --color-background-layout-toggle-selected-hover-mes33s: #ffffff !important;
  --color-background-modal-overlay-17dapf: hsla(0, 0%, 4%, 0.7) !important;
  --color-background-popover-mn5o9k: #19342f !important;
  --color-background-progress-bar-content-default-r4tyyh: #df6160 !important;
  --color-background-progress-bar-layout-default-9xbpym: #424242 !important;
  --color-background-segment-active-bl0u6r: #df6160 !important;
  --color-background-segment-default-5k37bj: hsl(0, 0%, 11%) !important;
  --color-background-segment-disabled-hgka10: hsl(0, 0%, 11%) !important;
  --color-background-segment-hover-s3f27a: hsl(0, 0%, 11%) !important;
  --color-background-status-info-usb30e: hsl(169, 100%, 8%) !important;
  --color-background-status-warning-zz4oi6: hsl(0, 28%, 16%) !important;
  --color-background-table-header-i1pa1g: hsl(0, 0%, 11%) !important;
  --color-background-tiles-disabled-byt5lx: #19342f !important;
  --color-background-toggle-checked-disabled-07lf06: #303030 !important;
  --color-background-toggle-default-w4ftus: #8a8a8a !important;
  --color-border-button-normal-active-zbmaft: #ffffff !important;
  --color-border-button-normal-default-djv8f5: #df6160 !important;
  --color-border-button-normal-disabled-262a0x: #6e6e6e !important;
  --color-border-button-normal-hover-e1d0kh: #ffffff !important;
  --color-border-button-primary-disabled-6gwabt: #424242 !important;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s: hsl(0, 0%, 11%) !important;
  --color-border-code-editor-default-l9vmjf: #6e6e6e !important;
  --color-border-code-editor-pane-item-hover-xephrt: #6e6e6e !important;
  --color-border-control-checked-4xvndc: #df6160 !important;
  --color-border-control-disabled-vx8bco: #424242 !important;
  --color-border-divider-default-7s2wjw: #4f4f4f !important;
  --color-border-divider-panel-bottom-zrjpck: hsl(0, 0%, 4%);
  --color-border-divider-panel-side-yjkfcr: #4f4f4f !important;
  --color-border-dropdown-container-tyn0js: #6e6e6e !important;
  --color-border-dropdown-group-0utpsr: #4f4f4f !important;
  --color-border-dropdown-item-default-faosd8: #4f4f4f !important;
  --color-border-dropdown-item-hover-tyzq9m: #6e6e6e !important;
  --color-border-input-default-e47bkv: #6e6e6e !important;
  --color-border-input-disabled-x2lopj: #19342f !important;
  --color-border-item-focused-ap3b6s: #df6160 !important;
  --color-border-item-placeholder-jgzhnb: #df6160 !important;
  --color-border-item-selected-ppkssz: #df6160 !important;
  --color-border-layout-wf5alz: #424242 !important;
  --color-border-popover-5pygk7: #6e6e6e !important;
  --color-border-segment-active-kdy5tm: #d6d6d6 !important;
  --color-border-segment-default-091088: #d6d6d6 !important;
  --color-border-segment-disabled-6v5zlt: #d6d6d6 !important;
  --color-border-segment-hover-9notuw: #d6d6d6 !important;
  --color-border-divider-interactive-default-rcasvw: #4f4f4f !important;
  --color-border-tabs-divider-69fx5i: #4f4f4f !important;
  --color-border-tabs-shadow-4lmh2z: hsl(0, 0%, 4%) !important;
  --color-border-tabs-underline-9bcfzu: #df6160 !important;
  --color-border-tiles-disabled-dp4h7y: #19342f !important;
  --color-border-tutorial-4ioksw: #4f4f4f !important;
  --color-foreground-control-default-7ajdem: #101010 !important;
  --color-foreground-control-disabled-v6a97u: hsl(0, 0%, 11%) !important;
  --color-shadow-default-3se70f: hsl(0, 0%, 4%) !important;
  --color-text-status-info-utupcz: #df6160 !important;
  --color-text-accent-s1eqko: #df6160 !important;
  --color-text-body-default-ajf1h5: #d6d6d6 !important;
  --color-text-body-secondary-fdstdf: #d6d6d6 !important;
  --color-text-breadcrumb-current-mum16s: #8a8a8a !important;
  --color-text-breadcrumb-icon-mlugp6: #6e6e6e !important;
  --color-text-button-inline-icon-default-w65lqn: #df6160 !important;
  --color-text-button-inline-icon-disabled-gfhvz7: #6e6e6e !important;
  --color-text-button-inline-icon-hover-wd5icp: #ffffff !important;
  --color-text-button-normal-active-6obrzh: #ffffff !important;
  --color-text-button-normal-default-u8647m: #df6160 !important;
  --color-text-button-normal-hover-kxmvcc: #ffffff !important;
  --color-text-link-button-normal-default-2yrp9u: #df6160 !important;
  --color-text-link-button-normal-hover-gz8y5b: #ffffff !important;
  --color-text-link-button-normal-active-8lhs8f: #ffffff !important;
  --color-text-button-primary-active-vems2x: #101010 !important;
  --color-text-button-primary-default-340wh6: #101010 !important;
  --color-text-button-primary-hover-vzqjpi: #101010 !important;
  --color-text-calendar-day-hover-graajh: #d6d6d6 !important;
  --color-text-calendar-month-elf7e9: hsl(0, 0%, 61%) !important;
  --color-text-code-editor-gutter-active-line-3r48bk: #101010 !important;
  --color-text-code-editor-gutter-default-q46j79: #d6d6d6 !important;
  --color-text-column-header-u472sv: #a8a8a8 !important;
  --color-text-column-sorting-icon-q5sr09: #a8a8a8 !important;
  --color-text-control-disabled-3z3eul: #6e6e6e !important;
  --color-text-counter-jwdegc: hsl(0, 0%, 61%) !important;
  --color-text-disabled-a2nkh4: #6e6e6e !important;
  --color-text-dropdown-footer-rj6jvf: hsl(0, 0%, 61%) !important;
  --color-text-dropdown-group-label-eaqlcl: hsl(0, 0%, 61%) !important;
  --color-text-dropdown-item-default-7tg9p2: #d6d6d6 !important;
  --color-text-dropdown-item-dimmed-jyqdrs: #6e6e6e !important;
  --color-text-dropdown-item-disabled-varol7: #6e6e6e !important;
  --color-text-dropdown-item-filter-match-x302if: #ffffff !important;
  --color-text-dropdown-item-highlighted-oaabyk: #ebebeb !important;
  --color-text-dropdown-item-secondary-0me7js: hsl(0, 0%, 61%) !important;
  --color-text-empty-fjv325: #d6d6d6 !important;
  --color-text-expandable-section-hover-73g3du: #df6160 !important;
  --color-text-expandable-section-navigation-icon-default-lb9rm0: #d6d6d6 !important;
  --color-text-form-default-yd29ox: #d6d6d6 !important;
  --color-text-form-label-k3j0b4: #d6d6d6 !important;
  --color-text-form-secondary-ih9x7l: hsl(0, 0%, 61%) !important;
  --color-text-group-label-w9to2i: hsl(0, 0%, 61%) !important;
  --color-text-heading-default-jopz0f: #ebebeb !important;
  --color-text-heading-secondary-corkaj: hsl(0, 0%, 61%) !important;
  --color-text-icon-subtle-bz5b7h: #a8a8a8 !important;
  --color-text-input-disabled-oawyzo: #6e6e6e !important;
  --color-text-input-placeholder-j53j3c: #8a8a8a !important;
  --color-text-input-placeholder-disabled-6928j8: #6e6e6e !important;
  --color-text-interactive-default-eg5fsa: #d6d6d6 !important;
  --color-text-interactive-disabled-3pbb07: #6e6e6e !important;
  --color-text-inverted-821sko: #101010 !important;
  --color-text-label-gxskdy: #d6d6d6 !important;
  --color-text-layout-toggle-selected-tzwifo: #101010 !important;
  --color-text-link-default-5f186r: #df6160 !important;
  --color-text-link-hover-kololx: #ffffff !important;
  --color-text-link-primary-underline-ga7rq8: #df6160 !important;
  --color-text-pagination-page-number-active-disabled-sjs1t5: #6e6e6e !important;
  --color-text-pagination-page-number-default-z6gnaa: #a8a8a8 !important;
  --color-text-segment-active-gc2jjl: #101010 !important;
  --color-text-segment-default-bf7rka: #d6d6d6 !important;
  --color-text-segment-hover-5pccvg: #df6160 !important;
  --color-text-small-le1y42: hsl(0, 0%, 61%) !important;
  --color-board-placeholder-active-vaxzdf: #6e6e6e !important;
  --color-board-placeholder-hover-v1s7kq: #e40501 !important;
  --shadow-container-fx563k: 0px 1px 8px 2px hsla(0, 0%, 4%, 0.6);
  --shadow-container-7nblnj: 0px 0px 1px 1px hsl(169, 35%, 15%), 0px 1px 8px 2px hsl(169, 100%, 4%);
  --shadow-container-stacked-jfo2nn: 0px 9px 8px -7px hsla(0, 0%, 4%, 0.6), 8px 0px 8px -7px hsla(0, 0%, 4%, 0.6), -8px 0px 8px -7px hsla(0, 0%, 4%, 0.6) !important;
  --shadow-container-active-l4kuxc: 0px 1px 1px 1px #19342f, 0px 6px 36px #101010 !important;
  --shadow-dropdown-99h0gz: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-dropup-11esug: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-flash-sticky-xplpiu: 0px 6px 36px hsl(0, 0%, 4%) !important;
  --shadow-modal-3qhlqt: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-panel-gp3bf2: 0px 1px 1px 1px #19342f, 0px 6px 36px hsl(0, 0%, 4%) !important;
  --shadow-panel-toggle-3fk0ch: 0px 6px 12px 1px hsl(0, 0%, 4%) !important;
  --shadow-popover-kzy6rd: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-split-bottom-otfetg: 0px -36px 36px -36px hsl(0, 0%, 4%) !important;
  --shadow-split-side-qv353u: -1px 0px 1px 0px #19342f, -36px 6px 36px -36px hsl(0, 0%, 4%) !important;
  --shadow-sticky-aijlo6: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-sticky-embedded-qit8ba: 0px 2px 0px 0px #4f4f4f, 0px 16px 16px -12px hsl(0, 0%, 4%) !important;
}

.awsui-context-top-navigation:not(#\9) {
  --color-background-button-link-active-t13dka: #424242 !important;
  --color-background-button-link-hover-69sbpz: #19342f !important;
  --color-background-button-normal-active-9lyks0: #424242 !important;
  --color-background-button-normal-default-syw2p1: hsl(168, 5%, 20%) !important;
  --color-background-button-normal-disabled-z5vd4i: hsl(168, 5%, 20%) !important;
  --color-background-button-normal-hover-c3qbgm: #19342f !important;
  --color-background-button-primary-active-vg0knj: #df6160 !important;
  --color-background-button-primary-default-pwn1g1: #df6160 !important;
  --color-background-button-primary-disabled-eon7km: #424242 !important;
  --color-background-button-primary-hover-qu1zio: #ffffff !important;
  --color-background-calendar-today-762czq: #424242 !important;
  --color-background-cell-shaded-26ubfj: hsl(0, 0%, 19%) !important;
  --color-background-code-editor-gutter-active-line-default-p2p8kl: #8a8a8a !important;
  --color-background-code-editor-gutter-default-l5k65m: #19342f !important;
  --color-background-code-editor-loading-4cqamf: #19342f !important;
  --color-background-code-editor-pane-item-hover-lrigi6: #424242 !important;
  --color-background-container-content-i8i4a0: hsl(1, 99%, 45%) !important;
  --color-background-container-header-4flbq5: hsl(0, 0%, 11%) !important;
  --color-background-control-checked-9admlu: #df6160 !important;
  --color-background-control-default-4pa05r: hsl(0, 0%, 11%) !important;
  --color-background-control-disabled-zplipy: #424242 !important;
  --color-background-dropdown-item-default-fzdqsg: hwb(169 11% 89%) !important;
  --color-background-dropdown-item-filter-match-6ac2mw: #FFF7F7 !important;
  --color-background-dropdown-item-hover-mqsxkv: #424242 !important;
  --color-background-dropdown-item-selected-8l4mpk: #19342f !important;
  --color-background-input-default-u57378: hsl(0, 0%, 11%) !important;
  --color-background-input-disabled-wu9j6u: #19342f !important;
  --color-background-item-selected-ebt4bi: #19342f !important;
  --color-background-layout-main-sfhm4y: hsl(0, 0%, 11%) !important;
  --color-background-layout-panel-content-808qum: hsl(0, 0%, 11%) !important;
  --color-background-layout-panel-hover-0bg3gq: #424242 !important;
  --color-background-layout-toggle-selected-active-9w7pk3: #df6160 !important;
  --color-background-layout-toggle-selected-default-e1giv3: #df6160 !important;
  --color-background-layout-toggle-selected-hover-mes33s: #ffffff !important;
  --color-background-modal-overlay-17dapf: hsla(0, 0%, 4%, 0.7) !important;
  --color-background-popover-mn5o9k: #19342f !important;
  --color-background-progress-bar-content-default-r4tyyh: #df6160 !important;
  --color-background-progress-bar-layout-default-9xbpym: #424242 !important;
  --color-background-segment-active-bl0u6r: #df6160 !important;
  --color-background-segment-default-5k37bj: hsl(0, 0%, 11%) !important;
  --color-background-segment-disabled-hgka10: hsl(0, 0%, 11%) !important;
  --color-background-segment-hover-s3f27a: hsl(0, 0%, 11%) !important;
  --color-background-table-header-i1pa1g: hsl(0, 0%, 11%) !important;
  --color-background-tiles-disabled-byt5lx: #19342f !important;
  --color-background-toggle-checked-disabled-07lf06: #303030 !important;
  --color-background-toggle-default-w4ftus: #8a8a8a !important;
  --color-border-button-normal-active-zbmaft: #ffffff !important;
  --color-border-button-normal-default-djv8f5: #df6160 !important;
  --color-border-button-normal-disabled-262a0x: #6e6e6e !important;
  --color-border-button-normal-hover-e1d0kh: #ffffff !important;
  --color-border-button-primary-disabled-6gwabt: #424242 !important;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s: hsl(0, 0%, 11%) !important;
  --color-border-code-editor-default-l9vmjf: #6e6e6e !important;
  --color-border-code-editor-pane-item-hover-xephrt: #6e6e6e !important;
  --color-border-control-checked-4xvndc: #df6160 !important;
  --color-border-control-disabled-vx8bco: #424242 !important;
  --color-border-divider-default-7s2wjw: #4f4f4f !important;
  --color-border-divider-panel-bottom-zrjpck: hsl(0, 0%, 4%) !important;
  --color-border-divider-panel-side-yjkfcr: #4f4f4f !important;
  --color-border-dropdown-container-tyn0js: #6e6e6e !important;
  --color-border-dropdown-group-0utpsr: #4f4f4f !important;
  --color-border-dropdown-item-default-faosd8: #4f4f4f !important;
  --color-border-dropdown-item-hover-tyzq9m: #6e6e6e !important;
  --color-border-input-default-e47bkv: #6e6e6e !important;
  --color-border-input-disabled-x2lopj: #19342f !important;
  --color-border-item-focused-ap3b6s: #df6160 !important;
  --color-border-item-placeholder-jgzhnb: #df6160 !important;
  --color-border-item-selected-ppkssz: #df6160 !important;
  --color-border-layout-wf5alz: #424242 !important;
  --color-border-popover-5pygk7: #6e6e6e !important;
  --color-border-segment-active-kdy5tm: #ffffff !important;
  --color-border-segment-default-091088: #ffffff !important;
  --color-border-segment-disabled-6v5zlt: #ffffff !important;
  --color-border-segment-hover-9notuw: #ffffff !important;
  --color-border-divider-interactive-default-rcasvw: #4f4f4f !important;
  --color-border-tabs-divider-69fx5i: #4f4f4f !important;
  --color-border-tabs-shadow-4lmh2z: hsl(0, 0%, 4%) !important;
  --color-border-tabs-underline-9bcfzu: #df6160 !important;
  --color-border-tiles-disabled-dp4h7y: #19342f !important;
  --color-border-tutorial-4ioksw: #4f4f4f !important;
  --color-foreground-control-default-7ajdem: #101010 !important;
  --color-foreground-control-disabled-v6a97u: hsl(0, 0%, 11%) !important;
  --color-shadow-default-3se70f: hsl(0, 0%, 4%) !important;
  --color-text-status-info-utupcz: #df6160 !important;
  --color-text-accent-s1eqko: #f49a9a !important;
  --color-text-body-default-ajf1h5: #ffffff !important;
  --color-text-body-secondary-fdstdf: #ffffff !important;
  --color-text-breadcrumb-current-mum16s: #8a8a8a !important;
  --color-text-breadcrumb-icon-mlugp6: #6e6e6e !important;
  --color-text-button-inline-icon-default-w65lqn: #df6160 !important;
  --color-text-button-inline-icon-disabled-gfhvz7: #6e6e6e !important;
  --color-text-button-inline-icon-hover-wd5icp: #ffffff !important;
  --color-text-button-normal-active-6obrzh: #ffffff !important;
  --color-text-button-normal-default-u8647m: #df6160 !important;
  --color-text-button-normal-hover-kxmvcc: #ffffff !important;
  --color-text-link-button-normal-default-2yrp9u: #df6160 !important;
  --color-text-link-button-normal-hover-gz8y5b: #ffffff !important;
  --color-text-link-button-normal-active-8lhs8f: #ffffff !important;
  --color-text-button-primary-active-vems2x: #101010 !important;
  --color-text-button-primary-default-340wh6: #101010 !important;
  --color-text-button-primary-hover-vzqjpi: #101010 !important;
  --color-text-calendar-day-hover-graajh: #ffffff !important;
  --color-text-calendar-month-elf7e9: hsl(0, 0%, 61%) !important;
  --color-text-code-editor-gutter-active-line-3r48bk: #101010 !important;
  --color-text-code-editor-gutter-default-q46j79: #ffffff !important;
  --color-text-column-header-u472sv: #a8a8a8 !important;
  --color-text-column-sorting-icon-q5sr09: #a8a8a8 !important;
  --color-text-control-disabled-3z3eul: #6e6e6e !important;
  --color-text-counter-jwdegc: hsl(0, 0%, 61%) !important;
  --color-text-disabled-a2nkh4: #6e6e6e !important;
  --color-text-dropdown-footer-rj6jvf: hsl(0, 0%, 61%) !important;
  --color-text-dropdown-group-label-eaqlcl: hsl(0, 0%, 61%) !important;
  --color-text-dropdown-item-default-7tg9p2: #ffffff !important;
  --color-text-dropdown-item-dimmed-jyqdrs: #6e6e6e !important;
  --color-text-dropdown-item-disabled-varol7: #6e6e6e !important;
  --color-text-dropdown-item-filter-match-x302if: #ffffff !important;
  --color-text-dropdown-item-highlighted-oaabyk: #ebebeb !important;
  --color-text-dropdown-item-secondary-0me7js: hsl(0, 0%, 61%) !important;
  --color-text-empty-fjv325: #ffffff !important;
  --color-text-expandable-section-hover-73g3du: #df6160 !important;
  --color-text-expandable-section-navigation-icon-default-lb9rm0: #ffffff !important;
  --color-text-form-default-yd29ox: #ffffff !important;
  --color-text-form-label-k3j0b4: #ffffff !important;
  --color-text-form-secondary-ih9x7l: hsl(0, 0%, 61%) !important;
  --color-text-group-label-w9to2i: hsl(0, 0%, 61%) !important;
  --color-text-heading-default-jopz0f: #ebebeb !important;
  --color-text-heading-secondary-corkaj: hsl(0, 0%, 61%) !important;
  --color-text-icon-subtle-bz5b7h: #a8a8a8 !important;
  --color-text-input-disabled-oawyzo: #6e6e6e !important;
  --color-text-input-placeholder-j53j3c: #8a8a8a !important;
  --color-text-input-placeholder-disabled-6928j8: #6e6e6e !important;
  --color-text-interactive-default-eg5fsa: #ffffff !important;
  --color-text-interactive-disabled-3pbb07: #6e6e6e !important;
  --color-text-inverted-821sko: #101010 !important;
  --color-text-label-gxskdy: #ffffff !important;
  --color-text-layout-toggle-selected-tzwifo: #101010 !important;
  --color-text-link-default-5f186r: #df6160 !important;
  --color-text-link-hover-kololx: #ffffff !important;
  --color-text-link-primary-underline-ga7rq8: #df6160 !important;
  --color-text-pagination-page-number-active-disabled-sjs1t5: #6e6e6e !important;
  --color-text-pagination-page-number-default-z6gnaa: #a8a8a8 !important;
  --color-text-segment-active-gc2jjl: #101010 !important;
  --color-text-segment-default-bf7rka: #ffffff !important;
  --color-text-segment-hover-5pccvg: #df6160 !important;
  --color-text-small-le1y42: hsl(0, 0%, 61%) !important;
  --color-board-placeholder-active-vaxzdf: #6e6e6e !important;
  --color-board-placeholder-hover-v1s7kq: #e40501 !important;
}

.awsui-context-content-header:not(#\9) {
  --color-background-button-link-active-t13dka: #424242 !important;
  --color-background-button-link-hover-69sbpz: #19342f !important;
  --color-background-button-normal-active-9lyks0: #424242 !important;
  --color-background-button-normal-default-syw2p1: hsl(168, 5%, 20%) !important;
  --color-background-button-normal-disabled-z5vd4i: hsl(168, 5%, 20%) !important;
  --color-background-button-normal-hover-c3qbgm: #19342f !important;
  --color-background-button-primary-active-vg0knj: #df6160 !important;
  --color-background-button-primary-default-pwn1g1: #df6160 !important;
  --color-background-button-primary-disabled-eon7km: #424242 !important;
  --color-background-button-primary-hover-qu1zio: #ffffff !important;
  --color-background-calendar-today-762czq: #424242 !important;
  --color-background-cell-shaded-26ubfj: hsl(0, 0%, 19%) !important;
  --color-background-code-editor-gutter-active-line-default-p2p8kl: #8a8a8a !important;
  --color-background-code-editor-gutter-default-l5k65m: #19342f !important;
  --color-background-code-editor-loading-4cqamf: #19342f !important;
  --color-background-code-editor-pane-item-hover-lrigi6: #424242 !important;
  --color-background-container-content-i8i4a0: hsl(1, 99%, 45%) !important;
  --color-background-container-header-4flbq5: hsl(0, 0%, 11%) !important;
  --color-background-control-checked-9admlu: #df6160 !important;
  --color-background-control-default-4pa05r: #101010 !important;
  --color-background-control-disabled-zplipy: #424242 !important;
  --color-background-dropdown-item-default-fzdqsg: #19342f !important;
  --color-background-dropdown-item-filter-match-6ac2mw: #424242 !important;
  --color-background-dropdown-item-hover-mqsxkv: #424242 !important;
  --color-background-dropdown-item-selected-8l4mpk: #19342f !important;
  --color-background-input-default-u57378: #101010 !important;
  --color-background-input-disabled-wu9j6u: #19342f !important;
  --color-background-item-selected-ebt4bi: #19342f !important;
  --color-background-layout-main-sfhm4y: #101010 !important;
  --color-background-layout-panel-content-808qum: hsl(0, 0%, 11%) !important;
  --color-background-layout-panel-hover-0bg3gq: #424242 !important;
  --color-background-layout-toggle-selected-active-9w7pk3: #df6160 !important;
  --color-background-layout-toggle-selected-default-e1giv3: #df6160 !important;
  --color-background-layout-toggle-selected-hover-mes33s: #ffffff !important;
  --color-background-modal-overlay-17dapf: hsla(0, 0%, 4%, 0.7) !important;
  --color-background-popover-mn5o9k: #19342f !important;
  --color-background-progress-bar-content-default-r4tyyh: #df6160 !important;
  --color-background-progress-bar-layout-default-9xbpym: #424242 !important;
  --color-background-segment-active-bl0u6r: #df6160 !important;
  --color-background-segment-default-5k37bj: #101010 !important;
  --color-background-segment-disabled-hgka10: #101010 !important;
  --color-background-segment-hover-s3f27a: #101010 !important;
  --color-background-status-info-usb30e: hsl(169, 100%, 8%) !important;
  --color-background-status-warning-zz4oi6: hsl(0, 28%, 16%) !important;
  --color-background-table-header-i1pa1g: #101010 !important;
  --color-background-tiles-disabled-byt5lx: #19342f !important;
  --color-background-toggle-checked-disabled-07lf06: #303030 !important;
  --color-background-toggle-default-w4ftus: #8a8a8a !important;
  --color-border-button-normal-active-zbmaft: #ffffff !important;
  --color-border-button-normal-default-djv8f5: #df6160 !important;
  --color-border-button-normal-disabled-262a0x: #6e6e6e !important;
  --color-border-button-normal-hover-e1d0kh: #ffffff !important;
  --color-border-button-primary-disabled-6gwabt: #424242 !important;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s: hsl(0, 0%, 11%) !important;
  --color-border-code-editor-default-l9vmjf: #6e6e6e !important;
  --color-border-code-editor-pane-item-hover-xephrt: #6e6e6e !important;
  --color-border-control-checked-4xvndc: #df6160 !important;
  --color-border-control-disabled-vx8bco: #424242 !important;
  --color-border-divider-default-7s2wjw: #4f4f4f !important;
  --color-border-divider-panel-bottom-zrjpck: hsl(0, 0%, 4%) !important;
  --color-border-divider-panel-side-yjkfcr: #4f4f4f !important;
  --color-border-dropdown-container-tyn0js: #6e6e6e !important;
  --color-border-dropdown-group-0utpsr: #4f4f4f !important;
  --color-border-dropdown-item-default-faosd8: #4f4f4f !important;
  --color-border-dropdown-item-hover-tyzq9m: #6e6e6e !important;
  --color-border-input-default-e47bkv: #6e6e6e !important;
  --color-border-input-disabled-x2lopj: #19342f !important;
  --color-border-item-focused-ap3b6s: #df6160 !important;
  --color-border-item-placeholder-jgzhnb: #df6160 !important;
  --color-border-item-selected-ppkssz: #df6160 !important;
  --color-border-layout-wf5alz: #424242 !important;
  --color-border-popover-5pygk7: #6e6e6e !important;
  --color-border-segment-active-kdy5tm: #d6d6d6 !important;
  --color-border-segment-default-091088: #d6d6d6 !important;
  --color-border-segment-disabled-6v5zlt: #d6d6d6 !important;
  --color-border-segment-hover-9notuw: #d6d6d6 !important;
  --color-border-divider-interactive-default-rcasvw: #4f4f4f !important;
  --color-border-tabs-divider-69fx5i: #4f4f4f !important;
  --color-border-tabs-shadow-4lmh2z: hsl(0, 0%, 4%) !important;
  --color-border-tabs-underline-9bcfzu: #df6160 !important;
  --color-border-tiles-disabled-dp4h7y: #19342f !important;
  --color-border-tutorial-4ioksw: #4f4f4f !important;
  --color-foreground-control-default-7ajdem: #101010 !important;
  --color-foreground-control-disabled-v6a97u: hsl(0, 0%, 11%) !important;
  --color-shadow-default-3se70f: hsl(0, 0%, 4%) !important;
  --color-text-status-info-utupcz: #e60301 !important;
  --color-text-accent-s1eqko: #df6160 !important;
  --color-text-body-default-ajf1h5: #d6d6d6 !important;
  --color-text-body-secondary-fdstdf: #d6d6d6 !important;
  --color-text-breadcrumb-current-mum16s: #8a8a8a !important;
  --color-text-breadcrumb-icon-mlugp6: #6e6e6e !important;
  --color-text-button-inline-icon-default-w65lqn: #df6160 !important;
  --color-text-button-inline-icon-disabled-gfhvz7: #6e6e6e !important;
  --color-text-button-inline-icon-hover-wd5icp: #ffffff !important;
  --color-text-button-normal-active-6obrzh: #ffffff !important;
  --color-text-button-normal-default-u8647m: #df6160 !important;
  --color-text-button-normal-hover-kxmvcc: #ffffff !important;
  --color-text-link-button-normal-default-2yrp9u: #df6160 !important;
  --color-text-link-button-normal-hover-gz8y5b: #ffffff !important;
  --color-text-link-button-normal-active-8lhs8f: #ffffff !important;
  --color-text-button-primary-active-vems2x: #101010 !important;
  --color-text-button-primary-default-340wh6: #101010 !important;
  --color-text-button-primary-hover-vzqjpi: #101010 !important;
  --color-text-calendar-day-hover-graajh: #d6d6d6 !important;
  --color-text-calendar-month-elf7e9: hsl(0, 0%, 61%) !important;
  --color-text-code-editor-gutter-active-line-3r48bk: #101010 !important;
  --color-text-code-editor-gutter-default-q46j79: #d6d6d6 !important;
  --color-text-column-header-u472sv: #a8a8a8 !important;
  --color-text-column-sorting-icon-q5sr09: #a8a8a8 !important;
  --color-text-control-disabled-3z3eul: #6e6e6e !important;
  --color-text-counter-jwdegc: hsl(0, 0%, 61%) !important;
  --color-text-disabled-a2nkh4: #6e6e6e !important;
  --color-text-dropdown-footer-rj6jvf: hsl(0, 0%, 61%) !important;
  --color-text-dropdown-group-label-eaqlcl: hsl(0, 0%, 61%) !important;
  --color-text-dropdown-item-default-7tg9p2: #d6d6d6 !important;
  --color-text-dropdown-item-dimmed-jyqdrs: #6e6e6e !important;
  --color-text-dropdown-item-disabled-varol7: #6e6e6e !important;
  --color-text-dropdown-item-filter-match-x302if: #ffffff !important;
  --color-text-dropdown-item-highlighted-oaabyk: #ebebeb !important;
  --color-text-dropdown-item-secondary-0me7js: hsl(0, 0%, 61%) !important;
  --color-text-empty-fjv325: #d6d6d6 !important;
  --color-text-expandable-section-hover-73g3du: #df6160 !important;
  --color-text-expandable-section-navigation-icon-default-lb9rm0: #d6d6d6 !important;
  --color-text-form-default-yd29ox: #d6d6d6 !important;
  --color-text-form-label-k3j0b4: #d6d6d6 !important;
  --color-text-form-secondary-ih9x7l: hsl(0, 0%, 61%) !important;
  --color-text-group-label-w9to2i: hsl(0, 0%, 61%) !important;
  --color-text-heading-default-jopz0f: #ebebeb !important;
  --color-text-heading-secondary-corkaj: hsl(0, 0%, 61%) !important;
  --color-text-icon-subtle-bz5b7h: #a8a8a8 !important;
  --color-text-input-disabled-oawyzo: #6e6e6e !important;
  --color-text-input-placeholder-j53j3c: #8a8a8a !important;
  --color-text-input-placeholder-disabled-6928j8: #6e6e6e !important;
  --color-text-interactive-default-eg5fsa: #d6d6d6 !important;
  --color-text-interactive-disabled-3pbb07: #6e6e6e !important;
  --color-text-inverted-821sko: #101010 !important;
  --color-text-label-gxskdy: #d6d6d6 !important;
  --color-text-layout-toggle-selected-tzwifo: #101010 !important;
  --color-text-link-default-5f186r: #df6160 !important;
  --color-text-link-hover-kololx: #ffffff !important;
  --color-text-link-primary-underline-ga7rq8: #df6160 !important;
  --color-text-pagination-page-number-active-disabled-sjs1t5: #6e6e6e !important;
  --color-text-pagination-page-number-default-z6gnaa: #a8a8a8 !important;
  --color-text-segment-active-gc2jjl: #101010 !important;
  --color-text-segment-default-bf7rka: #d6d6d6 !important;
  --color-text-segment-hover-5pccvg: #df6160 !important;
  --color-text-small-le1y42: hsl(0, 0%, 61%) !important;
  --color-board-placeholder-active-vaxzdf: #6e6e6e !important;
  --color-board-placeholder-hover-v1s7kq: #e40501 !important;
  --shadow-container-stacked-jfo2nn: 0px 9px 8px -7px hsla(0, 0%, 4%, 0.6), 8px 0px 8px -7px hsla(0, 0%, 4%, 0.6), -8px 0px 8px -7px hsla(0, 0%, 4%, 0.6) !important;
  --shadow-container-active-l4kuxc: 0px 1px 1px 1px #19342f, 0px 6px 36px #101010 !important;
  --shadow-dropdown-99h0gz: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-dropup-11esug: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-modal-3qhlqt: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-popover-kzy6rd: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-split-bottom-otfetg: 0px -36px 36px -36px hsl(0, 0%, 4%) !important;
  --shadow-split-side-qv353u: -1px 0px 1px 0px #19342f, -36px 6px 36px -36px hsl(0, 0%, 4%) !important;
  --shadow-sticky-aijlo6: 0px 4px 20px 1px hsl(0, 0%, 4%) !important;
  --shadow-sticky-embedded-qit8ba: 0px 2px 0px 0px #4f4f4f, 0px 16px 16px -12px hsl(0, 0%, 4%) !important;
}

.awsui-context-flashbar:not(#\9) {
  --color-background-button-normal-active-9lyks0: hsla(169, 100%, 4%, 0.2);
  --color-background-button-normal-hover-c3qbgm: hsla(169, 100%, 4%, 0.15);
}

.awsui-context-alert:not(#\9) {
  --color-background-button-normal-active-9lyks0: hsla(169, 100%, 4%, 0.1);
  --color-background-button-normal-hover-c3qbgm: hsla(169, 100%, 4%, 0.05);
  --color-background-button-primary-default-pwn1g1: #4f4f4f !important;
  --color-background-button-primary-hover-qu1zio: #101010 !important;
  --color-border-button-normal-active-zbmaft: #101010 !important;
  --color-border-button-normal-default-djv8f5: #4f4f4f !important;
  --color-border-button-normal-hover-e1d0kh: #101010 !important;
  --color-text-button-normal-active-6obrzh: #101010 !important;
  --color-text-button-normal-default-u8647m: #4f4f4f !important;
  --color-text-button-normal-hover-kxmvcc: #101010 !important;
  --color-text-link-button-normal-active-8lhs8f: #101010 !important;
}

.awsui-dark-mode .awsui-context-alert:not(#\9) {
  --color-background-button-primary-default-pwn1g1: #d6d6d6 !important;
  --color-border-button-normal-default-djv8f5: #d6d6d6 !important;
  --color-text-button-normal-default-u8647m: #d6d6d6 !important;
}

.awsui-context-alert.awsui-dark-mode:not(#\9) {
  --color-background-button-primary-default-pwn1g1: #d6d6d6 !important;
  --color-border-button-normal-default-djv8f5: #d6d6d6 !important;
  --color-text-button-normal-default-u8647m: #d6d6d6 !important;
}

.awsui_series--bar_1yz9c_1p6ht_94 > .awsui_series__rect_1yz9c_1p6ht_94:not(#\9) {
  --color-charts-palette-categorical-1-bonb16: #e60000;
  --color-charts-palette-categorical-2-emtquh: #f58178;
}

// .graphLMP .awsui_series--bar_1yz9c_1p6ht_94 > .awsui_series__rect_1yz9c_1p6ht_94:not(#\9) {
//   --color-charts-palette-categorical-1-bonb16: #bc7b04;
//   --color-charts-palette-categorical-2-emtquh: #ecc27f;
// }

.awsui_segment__path_1edmh_7a6ah_97:not(#\9) {
  --color-charts-palette-categorical-1-bonb16: #e60000;
    --color-charts-palette-categorical-2-emtquh: #4a4d4e;
}

.awsui_marker_1isd1_1g4d4_93, .awsui_marker--rectangle_1isd1_1g4d4_115{
  --color-charts-palette-categorical-1-bonb16: #e60000 ;
  --color-charts-palette-categorical-2-emtquh: #f58178;
}

.awsui_link_4c84z_vt4lg_93 {
  cursor: default !important
}

.awsui_variant-link_vjswe_1himx_294:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.customButton {
  background: #e40501 !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.awsui_primary-button_1xupv_1df87_391 > .awsui_root_1612d_iivw8_127:not(#\9) {
  display: none !important;
}
// .graphLMP > span.awsui_marker_1isd1_1g4d4_93, .awsui_marker--rectangle_1isd1_1g4d4_115 {
//   --color-charts-palette-categorical-1-bonb16: #bc7b04;
//   --color-charts-palette-categorical-2-emtquh: #ecc27f;
// }

//  div.barLMP.awsui_root_1gfe1_7b6p8_9.awsui_root_1yz9c_1p6ht_137 > div.awsui_root_18wu0_q9nws_93.awsui_box_18wu0_q9nws_207.awsui_m-top-m_18wu0_q9nws_870.awsui_color-default_18wu0_q9nws_207.awsui_font-size-default_18wu0_q9nws_223.awsui_font-weight-default_18wu0_q9nws_263 > div > div > div:nth-child(1) > span {
//   --color-charts-palette-categorical-1-bonb16: #e60000 ;
//  }

// div.barLMP.awsui_root_1gfe1_7b6p8_9.awsui_root_1yz9c_1p6ht_137 > div.awsui_root_18wu0_q9nws_93.awsui_box_18wu0_q9nws_207.awsui_m-top-m_18wu0_q9nws_870.awsui_color-default_18wu0_q9nws_207.awsui_font-size-default_18wu0_q9nws_223.awsui_font-weight-default_18wu0_q9nws_263 > div > div > div:nth-child(2) > span {
//   --color-charts-palette-categorical-2-emtquh: #f58178;
// }